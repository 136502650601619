export const msalconfig = {

    auth : {

        clientId : 'f4ed66f0-495f-4485-af11-9ffeb690c1a0',
        authority : 'https://login.microsoftonline.com/5870ff96-e2fc-4628-9f0a-9f5e80294a62',
        redirectURI : 'http://localhost:3000',
        postLogoutRedirectURI : 'http://localhost:3000',
        navigateToLoginRequestURL : false,
    
    },

    cache : {

        cachelocation : 'sessionStorage',
        storeAuthStateInCookie : false,

    },
};

export const loginRequest = {
    scopes : ['user.read'],
};
