import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

const cardTextStyle = {
  fontFamily: "Century Gothic, sans-serif",
};

function FormulaireMultiPage() {
  const [orderNumber, setOrderNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1); // etape actuelle du formulaire (1 ou 2)
  const [qualityChecks, setQualityChecks] = useState({
    visual: false,
    functional: false,
    durability: false,
    packaging: false,
  });

  const handleOrderNumberChange = (event) => {
    setOrderNumber(event.target.value);
    setErrorMessage(""); // Reinitialiser le message d'erreur lors de la saisie
  };

  const handleOrderNumberSubmit = (event) => {
    event.preventDefault();

    // Verifier si un numero de commande est saisi
    if (orderNumber.trim() === "") {
      setErrorMessage("Veuillez saisir un numero de commande.");
      return;
    }

    // Simuler la validation du numero d'article
    // Ici, nous pourrions effectuer une verification dans la base de donnees, etc.
    alert(`Numero d'article valide : ${orderNumber}`);

    // Passer à l'etape suivante
    setStep(2);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setQualityChecks({ ...qualityChecks, [name]: checked });
  };

  const handlePreviousStep = () => {
    setStep(1); // Revenir à l'etape precedente
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Soumission des contrôles qualite ici
    console.log("Contrôles qualite soumis :", qualityChecks);
    // Reinitialiser les contrôles après soumission
    setQualityChecks({
      visual: false,
      functional: false,
      durability: false,
      packaging: false,
    });
    // Reinitialiser l'etape à la première après soumission
    setStep(1);
  };

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h4" style={welcomeStyle}>Controle Qualitee</Card.Title>
            </Card.Header>
            <Card.Body style={cardTextStyle}>
              {step === 1 && (
                <Form onSubmit={handleOrderNumberSubmit}>
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Numero d'Article</Form.Label>
                        <Form.Control
                          type="text"
                          value={orderNumber}
                          onChange={handleOrderNumberChange}
                          placeholder="Entrez le numero d'article"
                          required
                        />
                        {errorMessage && (
                          <Form.Text className="text-danger">{errorMessage}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col  className="mt-2" md={12}>
                      <Button className="float-right" type="submit" variant="info">
                        Suivant
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
              {step === 2 && (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Contrôle visuel"
                          name="visual"
                          checked={qualityChecks.visual}
                          onChange={handleCheckboxChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Fonctionnement"
                          name="functional"
                          checked={qualityChecks.functional}
                          onChange={handleCheckboxChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Durabilite"
                          name="durability"
                          checked={qualityChecks.durability}
                          onChange={handleCheckboxChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Emballage"
                          name="packaging"
                          checked={qualityChecks.packaging}
                          onChange={handleCheckboxChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Button variant="info" onClick={handlePreviousStep} className="mr-2">
                        Precedent
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Button type="submit" variant="info">
                        Soumettre Contrôle Qualite
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default FormulaireMultiPage;
