import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap"; // Importez Row et Col pour la grille Bootstrap

class Footer extends Component {
  render() {
    const footerStyle = {
      opacity: 0.8, // Ajustez ce niveau selon vos besoins
      color: "black", // Mettre les écritures en noir
    };

    return (
      <footer className="footer px-0 px-lg-3" style={footerStyle}>
        <Container fluid>
          <Row className="justify-content-center justify-content-lg-between align-items-center">
            <Col lg="auto" className="text-center text-lg-left">
              <nav>
                <p className="copyright">
                  Pour toutes demandes relative au site. Contactez
                  <a href="mailto:bmiche@aeim54.fr"> Benjamin Michel.</a>
                </p>
              </nav>
            </Col>
            <Col lg="auto" className="text-center text-lg-left">
              <nav>
                <p className="copyright">
                  © {new Date().getFullYear()}
                  <a href="https://aeim.eu"> AEIM</a>
                  . Tous droits réservés.
                </p>
              </nav>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
