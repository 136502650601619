import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from './components/LoginPage/LoginPage';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import AdminLayout from "./layouts/Admin";

import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalconfig } from './assets/config/auth-config';

const msalInstance = new PublicClientApplication(msalconfig);

if (msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {

    msalInstance.setActiveAccount(msalInstance.getActiveAccount())

}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGINSUCCESS) {
      const account = event.payload.account;
      if (account) {
          msalInstance.setActiveAccount(account);
      } else {
          // Gérer le cas où le compte n'est pas défini
          console.error('Aucun compte défini après le succès de la connexion');
      }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
      <Switch>
        <LoginPage instance={msalInstance}/>
        <Route exact path="" component={LoginPage} />
        <Route path="" component={AdminLayout} />
      </Switch>
    </BrowserRouter>
);
