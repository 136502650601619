import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Card, Container} from "react-bootstrap";
import Select from 'react-select';

// Styles pour appliquer la police Century Gothic et les couleurs
const cardTextStyle = {
  fontFamily: "Century Gothic, sans-serif",
};

function UserList() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [SelectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]); // État pour stocker les utilisateurs
  const [currentPage, setCurrentPage] = useState(1); // État pour stocker la page actuelle
  const [usersPerPage, setUsersPerPage] = useState(10); // État pour stocker le nombre d'utilisateurs par page

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const handleUserChange = (option) => {
    setSelectedUser(option);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const userOptions = Object.keys(users).map((type) => ({
    value: type,
    label: `${type} - ${users[type].nom}`,
  }));

  const handleFilterSubmit = (event) => {
    event.preventDefault();
    // Ajoutez ici la logique pour filtrer les utilisateurs en fonction de searchTerm et selectedRole
    console.log("Filtrage par :", searchTerm, selectedRole);
  };

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('/api/users', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs:', error);
      }
    };
    fetchUsers();
  }, [searchTerm, selectedRole]); // Ré-exécute l'effet lorsque searchTerm ou selectedRole changent

  // Fonction pour paginer les utilisateurs
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Fonction pour calculer le nombre de pages
  const calculatePages = () => {
    const pages = Math.ceil(users.length / usersPerPage);
    return pages;
  };

  // Fonction pour afficher les utilisateurs paginés
  const displayUsers = () => {
    const startIndex = (currentPage - 1) * usersPerPage;
    const endIndex = startIndex + usersPerPage;
    return users.slice(startIndex, endIndex).map((user, index) => (
      <li key={index}>{user.nom}</li>
    ));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={cardTextStyle}>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>Liste des Utilisateurs</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleFilterSubmit}>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <Select
                          value={SelectedUser}
                          onChange={handleUserChange}
                          options={userOptions}
                          placeholder="Choisir un utilisateur..."
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Control
                          as="select"
                          value={selectedRole}
                          onChange={handleRoleChange}
                        >
                          <option value="">Tous les rôles</option>
                          <option value="admin">Admin</option>
                          <option value="user">Utilisateur</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Button type="submit" variant="info" block>
                        Filtrer
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col md="12">
                    <ul>
                      {displayUsers()}
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <nav aria-label="Page navigation">
                      <ul className="pagination">
                        {[...Array(calculatePages())].map((_, index) => (
                          <li key={index} className={currentPage === index + 1 ? 'active' : ''}>
                            <a onClick={() => paginate(index + 1)}>{index + 1}</a>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserList;