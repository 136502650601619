import React, { useState, useEffect , useContext } from 'react';
import { FaMinus, FaPlus, FaCheck, FaTrash } from "react-icons/fa";
import { Container, Row, Col, Table, Nav, Card, Form, Button, Modal } from 'react-bootstrap';
import { FaQuestionCircle, FaInfoCircle } from 'react-icons/fa';
import useWindowSize from '../assets/config/WindowSize.js'; // Importer le hook personnalise
import { AuthContext } from '../components/LoginPage/LoginPage.js';
import axios from 'axios'
import Select from 'react-select';

function Devis() {
  const size = useWindowSize(); // Utiliser le hook
  const isMobile = size.width <= 768; // Definir la condition pour mobile
  const { handleLogout, userDetails } = useContext(AuthContext);
  const [key, setKey] = useState('menage');
  const [currentPage, setCurrentPage] = useState(0);
  const [etablissement, setDefaultEtab] = useState("");
  const [SelectedEtab, setSelectedEtab] = useState("");
  const [SelectedClient, setSelectedClient] = useState("");
  const [showCriteriaPopup, setShowCriteriaPopup] = useState(false);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [client, setDefaultClient] = useState("");

  const handleCriteriaPopupShow = () => setShowCriteriaPopup(true);
  const handleCriteriaPopupClose = () => setShowCriteriaPopup(false);
  const handleHelpPopupShow = () => setShowHelpPopup(true);
  const handleHelpPopupClose = () => setShowHelpPopup(false);


  // etats pour le formulaire "Menage"
  const [nombrePiecesMenage, setNombrePiecesMenage] = useState('');
  const [typePieceMenage, setTypePieceMenage] = useState('');
  const [nombrePassagesMenage, setNombrePassagesMenage] = useState('');
  const [distanceMenage, setDistanceMenage] = useState(0);
  const [superficieMenage, setSuperficieMenage] = useState('');
  const [specificitesMenage, setSpecificitesMenage] = useState([]);
  const [specificiteMenage, setSpecificiteMenage] = useState('');
  const [articlesMenage, setArticlesMenage] = useState([]);
  const [prixProduitMenage, setPrixProduitMenage] = useState(0);
  const [prixMaterielMenage, setPrixMaterielMenage] = useState(0);
  const [margeMenage, setMargeMenage] = useState(0);
  const [nombreVehiculeMenage, setnombreVehiculeMenage] = useState(0);
  const [tempsMenage, setTempsMenage] = useState(0);



  // etats pour le formulaire "Espace Vert"
  const [typePrestationEspaceVert, setTypePrestationEspaceVert] = useState('');
  const [nombreInterventionsEspaceVert, setNombreInterventionsEspaceVert] = useState('');
  const [prestationExterne, setPrestationExterne] = useState('');
  const [superficieEspaceVert, setSuperficieEspaceVert] = useState('');
  const [specificitesEspaceVert, setSpecificitesEspaceVert] = useState([]);
  const [specificiteEspaceVert, setSpecificiteEspaceVert] = useState('');
  const [articlesEspaceVert, setArticlesEspaceVert] = useState([]);
  const [distanceEspaceVert, setDistanceEspaceVert] = useState(0);
  const [prixProduitEspaceVert, setPrixProduitEspaceVert] = useState(0);
  const [prixMaterielEspaceVert, setPrixMaterielEspaceVert] = useState(0);
  const [margeEspaceVert, setMargeEspaceVert] = useState(0);
  const [nombreVehiculeEspaceVert, setnombreVehiculeEspaceVert] = useState(0);
  const [tempsEV, setTempsEV] = useState(0);
  
  const handleEtabChange = (e) => {
    const selectedEtab = e.target.value;
    setSelectedEtab(selectedEtab);
    
  };

  const handleClientChange = (option) => {
    setSelectedClient(option);
  };

  useEffect(() => {
    if (userDetails?.mail) {
      axios.get('/etab_by_mail?email=' + userDetails.mail)
        .then(response => {
          const data = {};
          response.data.forEach(etab => {
          setSelectedEtab(etab.default_soc);
          data[etab.etab_soc] = {
            etab_nom: etab.etab_nom,
          };
        });
        setDefaultEtab(data);
      })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [userDetails.mail]);

  useEffect(() => {
    if (SelectedEtab) {
      axios.get('/client?soc=' + SelectedEtab)
        .then(response => {
          const data = {};
          response.data.forEach(client => {
            data[client.cpt] = {
              nom: client.nom,
              rue1: client.rue1,
              rue2: client.rue2,
              rue3: client.rue3,
              bp: client.bp,
              cp: client.cp,
              ville: client.ville,
              pays: client.pays,
              code_pays: client.code_pays,
              siret: client.siret,
              tva: client.tva,
              ape: client.ape,
              type: client.type,
              activite: client.activite,
            };
          });
          setDefaultClient(data);
          setClientOptions = Object.keys(data).map((type) => ({
            value: type,
            label: `${type} - ${data[type].nom}`,
          }));
          setClientOptions(clientOptions);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [SelectedEtab]);

  const clientOptions = Object.keys(client).map((type) => ({
    value: type,
    label: `${type} - ${client[type].nom}`,
  }));

  const handleSelectChangeMenage = (event) => {
    setDistanceMenage(event.target.value);
  };

  const handleSelectChangeEspaceVert = (event) => {
    setDistanceEspaceVert(event.target.value);
  };

  const handleAddSpecificiteMenage = () => {
    if (specificiteMenage) {
      if (specificiteMenage === "Nombre de poste de travail") {
        const nombrePostes = window.prompt("Veuillez entrer le nombre de postes de travail:");
        if (nombrePostes) {
          setSpecificitesMenage([...specificitesMenage, `${specificiteMenage} (${nombrePostes})`]);
        }
      } else {
        setSpecificitesMenage([...specificitesMenage, specificiteMenage]);
      }
      setSpecificiteMenage("");
    }
  };

  const distanceOptions = [
    { label: "Aucune", value: 0},
    { label: "Inferieur à 20 km.", value: 14 },
    { label: "Entre 20 et 40 km.", value: 28 },
    { label: "Entre 40 et 60 km.", value: 42 }
  ];

  const specificitesParTypeMenage = {
    "Bureau/Salle": ["Nombre de poste de travail"],
    "Sanitaire Simple": ["Bloc toilette supplementaire", "Bloc lavabo supplementaire"],
    "Douches": ["Douche cloisonnee"],
    "Couloir": ["Couloir non continu", "Retirer les tapis"],
    "Escalier": ["Moquette", "Bois-parquet"]
  };

  const specificitesParTypeEspaceVert = {
    "Remise en état - Ramassage des déchets": ["Moyennement encombré", "Très encombré"],
    "Remise en état - Evacuation des déchets non verts":[""],
    "Remise en état - Collecte et traitement": [""],
    "Tonte - Tonte autoportée/autotractée (avec ramassage)": ["Terrain en pente"],
    "Tonte - Tonte autoportée/autotractée (sans ramassage)": ["Terrain en pente"],
    "Débroussaillage - Débroussaillage (sans ramassage)": ["Terrain en pente"],
    "Ramassage de feuilles - Sur chemin bétonné-gazonné": ["Avec evacuation", "Terrain non stabilisé (Terre battue, pelouse,...)"]
  };

  const handleAddSpecificiteEspaceVert = () => {
    if (specificiteEspaceVert && !specificitesEspaceVert.includes(specificiteEspaceVert)) {
      setSpecificitesEspaceVert([...specificitesEspaceVert, specificiteEspaceVert]);
      setSpecificiteEspaceVert('');
    }
  };

  const clearFormMenage = () => {
    setNombrePiecesMenage('');
    setTypePieceMenage('');
    setNombrePassagesMenage('');
    setSuperficieMenage('');
    setSpecificitesMenage([]);
    setSpecificiteMenage('');
    setnombreVehiculeMenage('');
    setPrixProduitMenage('');
    setPrixMaterielMenage('');
    setMargeMenage('');
  };

  const clearFormEspaceVert = () => {
    setTypePrestationEspaceVert('');
    setNombreInterventionsEspaceVert('');
    setPrestationExterne('');
    setSuperficieEspaceVert('');
    setSpecificitesEspaceVert([]);
    setSpecificiteEspaceVert('');
    setnombreVehiculeEspaceVert('');
    setPrixProduitEspaceVert('');
    setPrixMaterielEspaceVert('');
    setMargeEspaceVert('');
  };

  const handleRemoveArticleMenage = (index) => {
    const newArticles = articlesMenage.filter((_, i) => i !== index);
    const removedArticle = articlesMenage[index];
    const tempsNettoyage = {
      "Bureau/Salle": 1,
      "Sanitaire Simple": 3,
      "Douches": 3,
      "Couloir": 0.5,
      "Escalier": 1
    };
    const tempsTravailMenage = tempsNettoyage[removedArticle.typePiece] * removedArticle.superficie * removedArticle.nombrePassages * removedArticle.nombrePieces;
    setArticlesMenage(newArticles);
    setTempsMenage(tempsMenage - tempsTravailMenage);
  };
  

  const handleRemoveArticleEspaceVert = (index) => {
    const articleSupprime = articlesEspaceVert[index];
    const newArticles = articlesEspaceVert.filter((_, i) => i !== index);
    const tempsPrestation = {
      "Remise en état - Ramassage des déchets": 2000, // 2000 minutes per square meter
      "Remise en état - Evacuation des déchets non verts": 180, // 3 minutes
      "Remise en état - Collecte et traitement": 180, // 3 minutes
      "Tonte - Tonte autoportée/autotractée (avec ramassage)": 300, // 1/300 minutes per square meter
      "Tonte - Tonte autoportée/autotractée (sans ramassage)": 600, // 1/600 minutes per square meter
      "Débroussaillage - Débroussaillage (sans ramassage)": 400, // 1/400 minutes per square meter
      "Ramassage de feuilles - Sur chemin bétonné-gazonné": 450, // 1/450 minutes per square meter
    };
  
    const calculerTempsTravail = (article) => {
      const tempsBase = tempsPrestation[article.TypePrestation];
      let tempsTravail = ((article.superficieEV/tempsBase)*60) * article.nombreInterventions;
  
      return tempsTravail;
    };
  
    const tempsTotalAvantSuppression = articlesEspaceVert.reduce((total, article) => total + calculerTempsTravail(article), 0);
  
    const tempsSupprime = calculerTempsTravail(articleSupprime);
  
    const nouveauTempsTotal = tempsTotalAvantSuppression - tempsSupprime;
  
    setArticlesEspaceVert(newArticles);
    setTempsEV(nouveauTempsTotal);
  };
  

  const calculerMontantMenage = (article) => {
    const coutHoraireAp = 9.28;
    const tempsNettoyage = {
      "Bureau/Salle": 1,
      "Sanitaire Simple": 3,
      "Douches": 3,
      "Couloir": 0.5,
      "Escalier": 1
    };

    const majMenage = {
      "Bloc toilette supplementaire": 0.6,
      "Bloc lavabo supplementaire": 0.15,
      "Douche cloisonnee": 0.3,
      "Couloir non continu": 0.05,
      "Retirer les tapis": 0.1,
      "Moquette": 1,
      "Bois-parquet": 1,
      "Nombre de poste de travail": 0.15
    };

    const tempsTravailMenage = tempsNettoyage[article.typePiece] * article.superficie * article.nombrePassages * article.nombrePieces
    const tempsBaseMenage = (tempsTravailMenage * coutHoraireAp) / 60;
    setTempsMenage(tempsMenage + tempsTravailMenage);

    let mt_majM = 0;
    for (let spec of article.specificites) {
        const nombrePostes = spec.match(/\d+/);
        const nomSpec = spec.split('(')[0].trim();
        if (majMenage[nomSpec] !== undefined) {
            mt_majM += nombrePostes ? (tempsBaseMenage * majMenage[nomSpec]) * nombrePostes[0] : tempsBaseMenage * majMenage[nomSpec];
        }
    }

    const montantMenage = tempsBaseMenage + mt_majM;
    return montantMenage.toFixed(2);
};


const calculerMontantEspaceVert = (articles) => {
  const coutHoraireApEspacerVert = 12.68;
  const tempsPresations = {
    "Remise en état - Ramassage des déchets": 2000, // m²/h
    "Remise en état - Evacuation des déchets non verts": 3 * 60, // 3 hours * 60 minutes
    "Remise en état - Collecte et traitement": 3 * 60, // 3 hours * 60 minutes
    "Tonte - Tonte autoportée/autotractée (avec ramassage)": 300, // m²/h
    "Tonte - Tonte autoportée/autotractée (sans ramassage)": 600, // m²/h
    "Débroussaillage - Débroussaillage (sans ramassage)": 400, // m²/h
    "Ramassage de feuilles - Sur chemin bétonné-gazonné": 450, // m²/h
  };

  const majEspaceVert = {
    "Moyennement encombré": 0.5,
    "Très encombré": 1,
    "Terrain en pente": 0.5,
    "Avec évacuation": 0.75,
    "Terrain non stabilisé": 0.5
  };

  let tempsBaseEV = 0;
  if (articles.TypePrestation.startsWith("Tonte")) {
    if (articles.nombreInterventions < 5) {
      tempsBaseEV = (articles.superficieEV / tempsPresations[articles.TypePrestation]) * coutHoraireApEspacerVert * 1.5; // 50% coefficient if less than 5 interventions
      setTempsEV(tempsEV + ((articles.superficieEV / tempsPresations[articles.TypePrestation])*60) * [articles.nombreInterventions]);
    } else if (articles.nombreInterventions < 3) {
      tempsBaseEV = (articles.superficieEV / tempsPresations[articles.TypePrestation]) * coutHoraireApEspacerVert * 1; // 100% coefficient if less than 3 interventions
      setTempsEV(tempsEV + ((articles.superficieEV / tempsPresations[articles.TypePrestation])*60) * [articles.nombreInterventions]);
    } else {
      tempsBaseEV = (articles.superficieEV / tempsPresations[articles.TypePrestation]) * coutHoraireApEspacerVert;
      setTempsEV(tempsEV + ((articles.superficieEV / tempsPresations[articles.TypePrestation])*60) * [articles.nombreInterventions]);
    }
    if (articles.superficieEV < 1000) {
      tempsBaseEV *= 1.35; // 35% coefficient if superficie is less than 1000m²
    }
  } else {
    const tempsTravailEV = (articles.superficieEV / tempsPresations[articles.TypePrestation])*[articles.nombreInterventions]
    tempsBaseEV = tempsTravailEV * coutHoraireApEspacerVert;
    setTempsEV(tempsEV + (tempsTravailEV*60));
  }


  let mt_majEV = 0;
  for (let spec of articles.specificitesEV) {
    const nomSpec = spec.split('(')[0].trim();
    if (majEspaceVert[nomSpec]!== undefined) {
      mt_majEV += (tempsBaseEV * majEspaceVert[nomSpec]);
    }
  }

  const montantEV = tempsBaseEV + mt_majEV;
  return montantEV.toFixed(2);
};

  const handleSubmitArticleMenage = (event) => {
    event.preventDefault();
    const article = {
      nombrePieces: nombrePiecesMenage,
      typePiece: typePieceMenage,
      nombrePassages: nombrePassagesMenage,
      superficie: superficieMenage,
      specificites: specificitesMenage,
      montant: calculerMontantMenage({
        nombrePieces: nombrePiecesMenage,
        typePiece: typePieceMenage,
        nombrePassages: nombrePassagesMenage,
        superficie: superficieMenage,
        specificites: specificitesMenage
      })
    };
    setArticlesMenage([...articlesMenage, article]);
    clearFormMenage();
  };

  const handleSubmitArticleEspaceVert = (event) => {
    event.preventDefault();
    const articles = {
      TypePrestation: typePrestationEspaceVert,
      nombreInterventions: nombreInterventionsEspaceVert,
      prestationExterne : prestationExterne,
      superficieEV: superficieEspaceVert,
      specificitesEV: specificitesEspaceVert,
      montant: calculerMontantEspaceVert({
        TypePrestation: typePrestationEspaceVert,
        nombreInterventions: nombreInterventionsEspaceVert,
        superficieEV: superficieEspaceVert,
        specificitesEV: specificitesEspaceVert
      })
    };
    setArticlesEspaceVert([...articlesEspaceVert, articles]);
    clearFormEspaceVert();
  };

  const calculerMontantTotalMenage = () => {
    const totalArticles = articlesMenage.reduce((total, article) => total + parseFloat(article.montant), 0);
    const coutKilometres = parseFloat(distanceMenage) * nombreVehiculeMenage;
    const prixProduits = parseFloat(prixMaterielMenage) + parseFloat(prixProduitMenage);
    const Marge = 1 + (parseFloat(margeMenage)/100)
    const montantTotal = (totalArticles + coutKilometres + prixProduits);
    const montantTotalHT =  Marge * montantTotal
    return { totalArticles: totalArticles.toFixed(2), coutKilometres: coutKilometres.toFixed(2), prixProduits: prixProduits.toFixed(2), montantTotal: montantTotal.toFixed(2),montantTotalHT: montantTotalHT.toFixed(2) };
  };

  const calculerMontantTotalEspaceVert = () => {
    const totalArticles = articlesEspaceVert.reduce((total, articles) => total + parseFloat(articles.montant), 0);
    const coutKilometres = parseFloat(distanceEspaceVert) * nombreVehiculeEspaceVert;
    const prixProduits = parseFloat(prixMaterielEspaceVert) + parseFloat(prixProduitEspaceVert);
    const Marge = 1 + (parseFloat(margeEspaceVert)/100)
    const montantTotal = (totalArticles + coutKilometres + prixProduits);
    const montantTotalHT = Marge * montantTotal
    return { totalArticles: totalArticles.toFixed(2), coutKilometres: coutKilometres.toFixed(2), prixProduits: prixProduits.toFixed(2), montantTotal: montantTotal.toFixed(2),montantTotalHT: montantTotalHT.toFixed(2) };
  };

  const montantTotalMenage = calculerMontantTotalMenage();
  const montantTotalEspaceVert = calculerMontantTotalEspaceVert();

  const handleValidationMenage = () => {
    alert(`Devis valide ! Montant Total Hors Taxe  (Menage) : ${montantTotalMenage.montantTotalHT} €`);
  };

  const handleValidationEspaceVert = () => {
    alert(`Devis valide ! Montant Total Hors Taxe  (Espace Vert) : ${montantTotalEspaceVert.montantTotalHT} €`);
  };

  const renderStep = () => {
    if (key === 'menage') {
      switch (currentPage) {
        case 0:
          return (
            <div>
           <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Création de devis</span>
                    <div style={{ marginLeft: 'auto' }}>
                      <FaQuestionCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleCriteriaPopupShow} />
                      <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                    </div>
                  </div>
                </Card.Title>
                <div>
                  <Modal show={showCriteriaPopup} onHide={handleCriteriaPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Critères de non réalisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez vous assurez qu'il n'y a pas de critère de non réalisation avant de commencer votre devis :
                      </p>
                      <p>
                        - Difficultés d'accès 
                      </p>
                      <p>
                        - Si risque de chutes du fait de l'encombrement matériel du terrain
                      </p>
                      <p>
                        - Condition météo (comme neige, verglas)
                      </p>
                      <p>
                        Ces critères sont très importants et doivent impérativement être pris en compte pour la sécuritée de nos travailleurs, toute action allant à l'encontre de ceux-ci entrainera de lourde sanctions
                      </p>                    
                    </Modal.Body>
                  </Modal>
                  <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Aide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez suivre les instructions ci-dessous :
                      </p>
                      <p>
                        - Vérifiez les coordonnées 
                      </p>
                      <p>
                        - Renseignez tous les champs
                      </p>
                      <p>
                        - Détaillez les services/produits
                      </p>
                      <p>
                        - Revérifiez les informations
                      </p>
                      <p>
                      Chaque champ est crucial pour la validité du devis. Une donnée manquante ou incorrecte peut entraîner des retards et des complications. Merci de votre coopération.
                      </p>
                    </Modal.Body>
                  </Modal>
                </div>
              </Card.Header>
                <Card.Body>
                  <Row className="mb-1">
                    <Col md="4">
                      <Form.Group>
                        <label>Etablissement</label>
                        <Form.Control
                          as="select"
                          value={SelectedEtab}
                          onChange={handleEtabChange}
                        >
                          <option value="">Choisir une option...</option>
                          {Object.keys(etablissement).map((type) => (
                          <option key={type} value={type}>{type + " - " + etablissement[type].etab_nom}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <label>Compte client</label>
                        <Form.Control
                          as="select"
                          value={SelectedClient}
                          onChange={handleClientChange}
                        >
                          <option value="">Choisir une option...</option>
                          {Object.keys(client).map((type) => (
                          <option key={type} value={type}>{type + " - " + client[type].nom}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
          <Card>
            <Card.Body>
              <Nav variant="tabs" activeKey={key} onSelect={(k) => { setKey(k); setCurrentPage(0); }} className='mb-3'>
                  <Nav.Item>
                    <Nav.Link eventKey="menage">Menage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="espaceVert">Espace Vert</Nav.Link>
                  </Nav.Item>
              </Nav>
              <Form.Group>
                <Form.Label>Nombre de pièces</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={nombrePiecesMenage}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setNombrePiecesMenage(newValue);
                    }
                  }}
                  placeholder="Ex : 3"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Type de pièce</Form.Label>
                <Form.Control
                  required
                  as="select"
                  value={typePieceMenage}
                  onChange={(e) => setTypePieceMenage(e.target.value)}
                >
                  <option value="">Selectionnez un type</option>
                  <option value="Bureau/Salle">Bureau/Salle</option>
                  <option value="Sanitaire Simple">Sanitaire Simple</option>
                  <option value="Douches">Douches</option>
                  <option value="Couloir">Couloir</option>
                  <option value="Escalier">Escalier</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Nombre de passages par mois</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={nombrePassagesMenage}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setNombrePassagesMenage(newValue);
                    }
                  }}
                  placeholder="Ex : 3"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Superficie (en m²)</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={superficieMenage}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setSuperficieMenage(newValue);
                    }
                  }}
                  placeholder="Ex : 50"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Specificites</Form.Label>
                <Form.Control
                  as="select"
                  value={specificiteMenage}
                  onChange={(e) => setSpecificiteMenage(e.target.value)}
                >
                  <option value="">Selectionnez une specificite</option>
                  {specificitesParTypeMenage[typePieceMenage]?.map((spec, index) => (
                    <option key={index} value={spec}>{spec}</option>
                  ))}
                </Form.Control>
              </Form.Group>
                <Button className='mt-3' onClick={handleAddSpecificiteMenage}>Ajouter Specificite</Button>
                <Button className='mt-3 ml-2' onClick={handleSubmitArticleMenage}>Valider Article</Button>
              <ul className='mt-2'>
                {specificitesMenage.map((spec, index) => (
                  <li key={index}>{spec}</li>
                ))}
              </ul>
            </Card.Body>
            </Card>
            <Card>
            <Card.Body>
                <Card.Header>
                  <Card.Title as="h5" style={welcomeStyle2} className='text-center'>Liste des articles</Card.Title>
                </Card.Header>
              <Form.Group>
                <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0"></th>
                        {key === 'menage' && (
                          <>
                            <th className="border-0">Nbre pièces</th>
                            <th className="border-0">Type de pièce</th>
                            <th className="border-0">Montant</th>
                          </>
                        )}
                        {key === 'espaceVert' && (
                          <>
                            <th className="border-0">Type de prestation</th>
                            <th className="border-0">Nbre interventions</th>
                            <th className="border-0">Superficie</th>
                            <th className="border-0">Specificites</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {key === 'menage' && articlesMenage.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleMenage(index)}
                            />
                          </td>
                          <td>{article.nombrePieces}</td>
                          <td>{article.typePiece}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                      {key === 'espaceVert' && articlesEspaceVert.map((articles, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleEspaceVert(index)}
                            />
                          </td>
                          <td>{articles.typePrestation}</td>
                          <td>{articles.superficie}</td>
                          <td>{articles.montant}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Form.Group>
              <Button className='float-right mt-3' onClick={() => {setCurrentPage(currentPage + 1)}}>Suivant</Button>
            </Card.Body>
            </Card>
            </div>
          );
        case 1:
          return (
            <div>
            <Card>
                <Card.Header>
                  <Card.Title as="h5" className='mx-3 text-center' style={welcomeStyle2}>Distance (Aller-Retour)</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form.Group className="w-100 mb-3">
                    <Form.Label>Distance :</Form.Label>
                    <Form.Control
                      as="select"
                      value={distanceMenage}
                      onChange={handleSelectChangeMenage}
                      className="w-100"
                    >
                      {distanceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="w-100">
                    <Form.Label>Nombre de véhicules :</Form.Label>
                    <Form.Control
                      required
                      value={nombreVehiculeMenage}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          setnombreVehiculeMenage(newValue);
                        }
                      }}
                      type="number"
                      className="w-100"
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            <Card>
              <Card.Body>
                    <Card.Header>
                      <Card.Title className='mx-3 text-center' as="h5" style={welcomeStyle2}>Produits/Matériel (€)</Card.Title>
                    </Card.Header>
                    <Form.Group className='w-100'>
                      <Form.Group className='mb-3'>
                        <label>Prix Produits</label>
                        <Form.Control
                          required
                          value={prixProduitMenage || 0}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= 0) {
                              setPrixProduitMenage(newValue);
                            }
                          }}
                          type="number"
                        />
                      </Form.Group>
                      <Form.Group className='mb-3'>
                        <label>Prix Matériel</label>
                        <Form.Control
                          required
                          value={prixMaterielMenage || 0}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= 0) {
                              setPrixMaterielMenage(newValue);
                            }
                          }}
                          type="number"
                        />
                      </Form.Group>
                    </Form.Group>
              </Card.Body>
              <Card.Footer className="align-items-center">
                <Button className='float-left' onClick={() => setCurrentPage(currentPage - 1)}>Precedent</Button>
                <Button className='float-right' onClick={() => setCurrentPage(currentPage + 1)}>Suivant</Button>
              </Card.Footer>
            </Card>
            </div>
          );
        case 2:
          return (
            <Card className="mt-3">
            <Card.Header>
              <Card.Title className='text-center' as="h4" style={welcomeStyle2}>Montant Total</Card.Title>
            </Card.Header>  
              <Card.Body>
              <div style={{ fontSize: "0.9rem" }}>
                      <h5>Temps de travail : {(tempsMenage / 60).toFixed(2).toString().split('.')[0]}H{(((parseInt((tempsMenage / 60).toFixed(2).toString().split('.')[1])) / 100) * 60).toFixed(0)}M</h5>
                      <h5>Deplacement : {montantTotalMenage.coutKilometres} €</h5>
                      <h5>Produits/Matériels : {isNaN(montantTotalMenage.prixProduits) ? 0 : montantTotalMenage.prixProduits} €</h5>
                      <h5>Coût de revient : {montantTotalMenage.montantTotal} €</h5>
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0">Marge (%) :</h5>
                        <Form.Control
                          required
                          value={margeMenage || 0}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= 0) {
                              setMargeMenage(newValue);
                            }
                          }}
                          placeholder="Marge (%)"
                          type="number"
                          className="ml-2"
                          style={{ width: '100px' }} // Optionnel : pour contrôler la largeur du champ de saisie
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <h5 className="mb-0">Montant (HT) : {montantTotalMenage.montantTotalHT} €</h5>
                      </div>
                    </div>
                <div className="mt-3 d-flex justify-content-center">
                  <Button onClick={() => setCurrentPage(currentPage - 1)}>Precedent</Button>
                  <Button onClick={handleValidationMenage} className="ml-3">Soumettre</Button>
                </div>
              </Card.Body>
            </Card>
          );
        default:
          return null;
      }
    } else if (key === 'espaceVert') {
      switch (currentPage) {
        case 0:
          return (
            <div>
            <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Création de devis</span>
                    <div style={{ marginLeft: 'auto' }}>
                      <FaQuestionCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleCriteriaPopupShow} />
                      <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                    </div>
                  </div>
                </Card.Title>
                <div>
                  <Modal show={showCriteriaPopup} onHide={handleCriteriaPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Critères de non réalisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez vous assurez qu'il n'y a pas de critère de non réalisation avant de commencer votre devis :
                      </p>
                      <p>
                        - Difficultés d'accès 
                      </p>
                      <p>
                        - Si risque de chutes du fait de l'encombrement matériel du terrain
                      </p>
                      <p>
                        - Condition météo (comme neige, verglas)
                      </p>
                      <p>
                        Ces critères sont très importants et doivent impérativement être pris en compte pour la sécuritée de nos travailleurs, toute action allant à l'encontre de ceux-ci entrainera de lourde sanctions
                      </p>                    
                    </Modal.Body>
                  </Modal>
                  <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Aide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez suivre les instructions ci-dessous :
                      </p>
                      <p>
                        - Vérifiez les coordonnées 
                      </p>
                      <p>
                        - Renseignez tous les champs
                      </p>
                      <p>
                        - Détaillez les services/produits
                      </p>
                      <p>
                        - Revérifiez les informations
                      </p>
                      <p>
                      Chaque champ est crucial pour la validité du devis. Une donnée manquante ou incorrecte peut entraîner des retards et des complications. Merci de votre coopération.
                      </p>
                    </Modal.Body>
                  </Modal>
                </div>
              </Card.Header>
                <Card.Body>
                  <Row className="mb-1">
                    <Col md="4">
                    <Form.Group>
                        <label>Etablissement</label>
                        <Form.Control
                          as="select"
                          value={SelectedEtab}
                          onChange={handleEtabChange}
                        >
                          <option value="">Choisir une option...</option>
                          {Object.keys(etablissement).map((type) => (
                          <option key={type} value={type}>{type + " - " + etablissement[type].etab_nom}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <label>Compte client</label>
                        <Select
                          value={SelectedClient}
                          onChange={handleClientChange}
                          options={clientOptions}
                          placeholder="Choisir une option..."
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
          <Card>
            <Card.Body>
              <Nav variant="tabs" activeKey={key} onSelect={(k) => { setKey(k); setCurrentPage(0); }} className='mb-3'>
                  <Nav.Item>
                    <Nav.Link eventKey="menage">Menage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="espaceVert">Espace Vert</Nav.Link>
                  </Nav.Item>
              </Nav>
              <Form.Group>
                <Form.Label>Type de prestation</Form.Label>
                <Form.Control
                  required
                  as="select"
                  value={typePrestationEspaceVert}
                  onChange={(e) => setTypePrestationEspaceVert(e.target.value)}
                >
                  <option value="">Selectionnez un type</option>
                  <option value="Remise en état - Ramassage des déchets">Remise en état - Ramassage des déchets</option>
                  <option value="Remise en état - Evacuation des déchets non verts">Remise en état - Evacuation des déchets non verts</option>
                  <option value="Remise en état - Collecte et traitement">Remise en état - Collecte et traitement</option>
                  <option value="Tonte - Tonte autoportée/autotractée (avec ramassage)">Tonte - Tonte autoportée/autotractée (avec ramassage)</option>
                  <option value="Tonte - Tonte autoportée/autotractée (sans ramassage)">Tonte - Tonte autoportée/autotractée (sans ramassage)</option>
                  <option value="Débroussaillage - Débroussaillage (sans ramassage)">Débroussaillage - Débroussaillage (sans ramassage)</option>
                  <option value="Ramassage de feuilles - Sur chemin bétonné-gazonné">Ramassage de feuilles - Sur chemin bétonné-gazonné</option>
                </Form.Control>
                <Form.Label>Nombre d'interventions par mois</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={nombreInterventionsEspaceVert}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setNombreInterventionsEspaceVert(newValue);
                    }
                  }}
                  placeholder="Ex : 3"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Superficie (en m²)</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={superficieEspaceVert}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setSuperficieEspaceVert(newValue);
                    }
                  }}
                  placeholder="Ex : 100"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                  <label>Specificites</label>
                  <Form.Control
                    as="select"
                    value={specificiteEspaceVert}
                    onChange={(e) => setSpecificiteEspaceVert(e.target.value)}
                    disabled={["Remise en état - Evacuation des déchets non verts", "Remise en état - Collecte et traitement"].includes(typePrestationEspaceVert)}
                  >
                    <option value="">Choisir une option...</option>
                    {specificitesParTypeEspaceVert[typePrestationEspaceVert] && specificitesParTypeEspaceVert[typePrestationEspaceVert].map((spec) => (
                      <option key={spec} value={spec}>{spec}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button className='mt-3' onClick={handleAddSpecificiteEspaceVert}>Ajouter Specificite</Button>
                <Button className='mt-3 ml-2' onClick={handleSubmitArticleEspaceVert}>Valider Article</Button>
              <ul className='mt-2'>
                {specificitesEspaceVert.map((spec, index) => (
                  <li key={index}>{spec}</li>
                ))}
              </ul>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
                <Card.Header>
                  <Card.Title as="h5" style={welcomeStyle2} className='text-center'>Liste des articles</Card.Title>
                </Card.Header>
              <Form.Group>
                <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0"></th>
                        {key === 'menage' && (
                          <>
                            <th className="border-0">Nbre pièces</th>
                            <th className="border-0">Type de pièce</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                        {key === 'espaceVert' && (
                          <>
                            <th className="border-0">Type de prestation</th>
                            <th className="border-0">Nbre interventions</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {key === 'menage' && articlesMenage.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleMenage(index)}
                            />
                          </td>
                          <td>{article.nombrePieces}</td>
                          <td>{article.typePiece}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                      {key === 'espaceVert' && articlesEspaceVert.map((articles, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleEspaceVert(index)}
                            />
                          </td>
                          <td>{articles.TypePrestation}</td>
                          <td>{articles.nombreInterventions}</td>
                          <td>{articles.montant}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Form.Group>
              <Button className='float-right mt-3' onClick={() => {setCurrentPage(currentPage + 1)}}>Suivant</Button>
            </Card.Body>
            </Card>
            </div>
          );
          case 1:
            return (
              <div>
              <Card>
                <Card.Header>
                  <Card.Title as="h5" className='mx-3 text-center' style={welcomeStyle2}>Distance (Aller-Retour)</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form.Group className="w-100 mb-3">
                    <Form.Label>Distance :</Form.Label>
                    <Form.Control
                      as="select"
                      value={distanceEspaceVert}
                      onChange={handleSelectChangeEspaceVert}
                      className="w-100"
                    >
                      {distanceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="w-100">
                    <Form.Label>Nombre de véhicules :</Form.Label>
                    <Form.Control
                      required
                      value={nombreVehiculeEspaceVert}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          setnombreVehiculeEspaceVert(newValue);
                        }
                      }}
                      type="number"
                      className="w-100"
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
              <Card>
              <Card.Body>
                <Card.Header>
                  <Card.Title className='mx-3 text-center' as="h5" style={welcomeStyle2}>Produits/Matériel (€)</Card.Title>
                </Card.Header>
                <Form.Group className='w-100'>
                  <Form.Group className='mb-3'>
                    <label>Prix Produits</label>
                    <Form.Control
                      required
                      value={prixProduitEspaceVert || 0}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          setPrixProduitEspaceVert(newValue);
                        }
                      }}
                      type="number"
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <label>Prix Matériel</label>
                    <Form.Control
                      required
                      value={prixMaterielEspaceVert || 0}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          setPrixMaterielEspaceVert(newValue);
                        }
                      }}
                      type="number"
                    />
                  </Form.Group>
                </Form.Group>
              </Card.Body>
              <Card.Footer className="align-items-center">
                <Button className='float-left' onClick={() => setCurrentPage(currentPage - 1)}>Precedent</Button>
                <Button className='float-right' onClick={() => setCurrentPage(currentPage + 1)}>Suivant</Button>
              </Card.Footer>
            </Card>
              </div>
            );
          case 2:
            return (
              <Card className="mt-3">
              <Card.Header>
                <Card.Title className='text-center' as="h4" style={welcomeStyle2}>Montant Total</Card.Title>
              </Card.Header>  
                <Card.Body>
                <div style={{ fontSize: "0.9rem" }}>
                        <h5>Temps de travail : {(tempsEV / 60).toFixed(2).toString().split('.')[0]}H{(((parseInt((tempsEV / 60).toFixed(2).toString().split('.')[1])) / 100) * 60).toFixed(0)}M</h5>
                        <h5>Deplacement : {montantTotalEspaceVert.coutKilometres} €</h5>
                        <h5>Produits/Matériels : {isNaN(montantTotalEspaceVert.prixProduits) ? 0 : montantTotalEspaceVert.prixProduits} €</h5>
                        <h5>Coût de revient : {montantTotalEspaceVert.montantTotal} €</h5>
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0">Marge (%) :</h5>
                          <Form.Control
                            required
                            value={margeEspaceVert || 0}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setMargeEspaceVert(newValue);
                              }
                            }}
                            placeholder="Marge (%)"
                            type="number"
                            className="ml-2"
                            style={{ width: '100px' }} // Optionnel : pour contrôler la largeur du champ de saisie
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <h5 className="mb-0">Montant (HT) : {montantTotalEspaceVert.montantTotalHT} €</h5>
                        </div>
                      </div>
                  <div className="mt-3 d-flex justify-content-center">
                    <Button onClick={() => setCurrentPage(currentPage - 1)}>Precedent</Button>
                    <Button onClick={handleValidationEspaceVert} className="ml-3">Soumettre</Button>
                  </div>
                </Card.Body>
              </Card>
            );
        default:
          return null;
      }
    }
  };

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  const welcomeStyle2 = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  const montantStyle = {
    fontSize: '1.0rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  return (
    <Container>
      {isMobile ? (
        renderStep()
      ) : (
        <>
          <Row>
            <Col>
              <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Création de devis</span>
                    <div style={{ marginLeft: 'auto' }}>
                      <FaQuestionCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleCriteriaPopupShow} />
                      <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                    </div>
                  </div>
                </Card.Title>
                <div>
                  <Modal show={showCriteriaPopup} onHide={handleCriteriaPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Critères de non réalisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez vous assurez qu'il n'y a pas de critère de non réalisation avant de commencer votre devis :
                      </p>
                      <p>
                        - Difficultés d'accès 
                      </p>
                      <p>
                        - Si risque de chutes du fait de l'encombrement matériel du terrain
                      </p>
                      <p>
                        - Condition météo (comme neige, verglas)
                      </p>
                      <p>
                        Ces critères sont très importants et doivent impérativement être pris en compte pour la sécuritée de nos travailleurs, toute action allant à l'encontre de ceux-ci entrainera de lourde sanctions
                      </p>                    
                    </Modal.Body>
                  </Modal>
                  <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Aide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez suivre les instructions ci-dessous :
                      </p>
                      <p>
                        - Vérifiez les coordonnées 
                      </p>
                      <p>
                        - Renseignez tous les champs
                      </p>
                      <p>
                        - Détaillez les services/produits
                      </p>
                      <p>
                        - Revérifiez les informations
                      </p>
                      <p>
                      Chaque champ est crucial pour la validité du devis. Une donnée manquante ou incorrecte peut entraîner des retards et des complications. Merci de votre coopération.
                      </p>
                    </Modal.Body>
                  </Modal>
                </div>
              </Card.Header>
                <Card.Body>
                  <Row className="mb-1">
                    <Col md="4">
                    <Form.Group>
                        <label>Etablissement</label>
                        <Form.Control
                          as="select"
                          value={SelectedEtab}
                          onChange={handleEtabChange}
                        >
                          <option value="">Choisir une option...</option>
                          {Object.keys(etablissement).map((type) => (
                          <option key={type} value={type}>{type + " - " + etablissement[type].etab_nom}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <label>Compte client</label>
                        <Select
                          value={SelectedClient}
                          onChange={handleClientChange}
                          options={clientOptions}
                          placeholder="Choisir une option..."
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Nav variant="tabs" activeKey={key} onSelect={(k) => { setKey(k); setCurrentPage(0); }} className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="menage">Menage</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="espaceVert">Espace Vert</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {key === 'menage' && (
                    <Form>
                      <Row className="mb-1">
                        <Col md="4" xs="12">
                          <Form.Group>
                            <label>Type Pièce</label>
                            <Form.Control
                              as="select"
                              value={typePieceMenage}
                              onChange={(e) => setTypePieceMenage(e.target.value)}
                            >
                              <option value="">Choisir une option...</option>
                              {Object.keys(specificitesParTypeMenage).map((type) => (
                                <option key={type} value={type}>{type}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="2" xs="6">
                          <Form.Group>
                            <label>Nombre de pièces</label>
                            <Form.Control
                              value={nombrePiecesMenage}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 1) { setNombrePiecesMenage(newValue); } }}
                              placeholder="Ex : 3"
                              type="number"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="3" xs="6">
                          <Form.Group>
                            <label>Nombre de passages</label>
                            <Form.Control
                              value={nombrePassagesMenage}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 1) { setNombrePassagesMenage(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="3" xs="6">
                          <Form.Group>
                            <label>Superficie (m²)</label>
                            <Form.Control
                              value={superficieMenage}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 1) { setSuperficieMenage(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="8" xs="8">
                          <Form.Group>
                            <label>Specificites</label>
                            <Form.Control
                              as="select"
                              value={specificiteMenage}
                              onChange={(e) => setSpecificiteMenage(e.target.value)}
                            >
                              <option value="">Choisir une option...</option>
                              {specificitesParTypeMenage[typePieceMenage] && specificitesParTypeMenage[typePieceMenage].map((spec) => (
                                <option key={spec} value={spec}>{spec}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="4" xs="4" className="d-flex align-items-end justify-content-end">
                          <Button className="btn-fill mx-1" type="button" variant="primary" onClick={handleAddSpecificiteMenage}>
                            <FaPlus />
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="d-flex justify-content-end">
                          <Button className="btn-fill" type="button" variant="info" onClick={handleSubmitArticleMenage}> Ajouter Article </Button>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Form>
                  )}
                  {key === 'espaceVert' && (
                    <Form>
                      <Row className="mb-1">
                        <Col md="4" xs="12">
                          <Form.Group>
                            <label>Type Prestation</label>
                            <Form.Control
                              as="select"
                              value={typePrestationEspaceVert}
                              onChange={(e) => setTypePrestationEspaceVert(e.target.value)}
                            >
                              <option value="">Choisir une option...</option>
                              {Object.keys(specificitesParTypeEspaceVert).map((type) => (
                                <option key={type} value={type}>{type}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="2" xs="6">
                          <Form.Group>
                            <label>Valeur au réel (€)</label>
                            <Form.Control
                              value={prestationExterne}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 1) { setPrestationExterne(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                              disabled={["Remise en état - Ramassage des déchets", "Tonte - Tonte autoportée/autotractée (avec ramassage)",  "Tonte - Tonte autoportée/autotractée (sans ramassage)", "Débroussaillage - Débroussaillage (sans ramassage)", "Ramassage de feuilles - Sur chemin bétonné-gazonné"].includes(typePrestationEspaceVert)}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="3" xs="6">
                          <Form.Group>
                            <label>Nombre d'intervention</label>
                            <Form.Control
                              value={nombreInterventionsEspaceVert}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 1) { setNombreInterventionsEspaceVert(newValue); } }}
                              placeholder="Ex : 3"
                              type="number"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="3" xs="6">
                          <Form.Group>
                            <label>Superficie (m²)</label>
                            <Form.Control
                              value={superficieEspaceVert}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 1) { setSuperficieEspaceVert(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="8" xs="8">
                          <Form.Group>
                            <label>Specificites</label>
                            <Form.Control
                              as="select"
                              value={specificiteEspaceVert}
                              onChange={(e) => setSpecificiteEspaceVert(e.target.value)}
                              disabled={["Remise en état - Evacuation des déchets non verts", "Remise en état - Collecte et traitement"].includes(typePrestationEspaceVert)}
                            >
                              <option value="">Choisir une option...</option>
                              {specificitesParTypeEspaceVert[typePrestationEspaceVert] && specificitesParTypeEspaceVert[typePrestationEspaceVert].map((spec) => (
                                <option key={spec} value={spec}>{spec}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="4" xs="4" className="d-flex align-items-end justify-content-end">
                          <Button className="btn-fill mx-1" type="button" variant="primary" onClick={handleAddSpecificiteEspaceVert}>
                            <FaPlus />
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="d-flex justify-content-end">
                          <Button className="btn-fill" type="button" variant="info" onClick={handleSubmitArticleEspaceVert}> Ajouter Article </Button>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Form>
                  )}
                </Card.Body>
              </Card>
            </Col>
            </Row>
            <Row>
            <Col md="12">
            {key === 'menage' && (
                <>
                <Row>
                  <Col md="4">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Distance (Aller-Retour)</Card.Title>
                      </Card.Header>
                      <Card.Body className="d-flex align-items-center">
                        <Form.Group className="w-100">
                          <label>Distance</label>
                          <Form.Control
                            as="select"
                            value={distanceMenage}
                            onChange={handleSelectChangeMenage}
                          >
                            {distanceOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                          <label>Nombre de véhicules</label>
                          <Form.Control
                            required
                            value={nombreVehiculeMenage}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setnombreVehiculeMenage(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="8">
                  <Card>
                    <Card.Header>
                      <Card.Title className='mx-3 text-center' as="h5" style={welcomeStyle2}>Produits/Matériel (€)</Card.Title>
                    </Card.Header>
                    <Card.Body className="d-flex align-items-center">
                      <Col>
                        <Form.Group>
                          <label>Prix Produits</label>
                          <Form.Control
                            required
                            value={prixProduitMenage || 0}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setPrixProduitMenage(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label>Prix Matériel</label>
                          <Form.Control
                            required
                            value={prixMaterielMenage || 0}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setPrixMaterielMenage(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                      </Col>
                    </Card.Body>
                  </Card>
                  </Col>
                </Row>
                </>
              )}  
              {key === 'espaceVert' && (
                <>
                <Row>
                  <Col md="4">
                  <Card>
                      <Card.Header>
                        <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Distance (Aller-Retour)</Card.Title>
                      </Card.Header>
                      <Card.Body className="d-flex align-items-center">
                        <Form.Group className="w-100">
                          <label>Distance</label>
                          <Form.Control
                            as="select"
                            value={distanceEspaceVert}
                            onChange={handleSelectChangeEspaceVert}
                          >
                            {distanceOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                        <label>Nombre de véhicules</label>
                          <Form.Control
                            required
                            value={nombreVehiculeEspaceVert}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setnombreVehiculeEspaceVert(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="8">
                    <Card>
                      <Card.Header>
                        <Card.Title className='text-center' as="h5" style={welcomeStyle2}>Produits/Matériel (€)</Card.Title>
                      </Card.Header>
                      <Card.Body className="d-flex align-items-center">
                        <Col>
                          <Form.Group>
                            <label>Prix Produits</label>
                            <Form.Control
                              required
                              value={prixProduitEspaceVert || 0}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (!isNaN(newValue) && newValue >= 0) {
                                  setPrixProduitEspaceVert(newValue);
                                }
                              }}
                              placeholder="Produit (€)"
                              type="number"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <label>Prix Matériel</label>
                            <Form.Control
                              required
                              value={prixMaterielEspaceVert || 0}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (!isNaN(newValue) && newValue >= 0) {
                                  setPrixMaterielEspaceVert(newValue);
                                }
                              }}
                              placeholder="Matériel (€)"
                              type="number"
                            />
                          </Form.Group>
                        </Col>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Card className="mr-3">
                <Card.Header>
                  <Card.Title as="h5" style={welcomeStyle2} className='text-center'>Liste des articles</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0"></th>
                        {key === 'menage' && (
                          <>
                            <th className="border-0">Type de pièce</th>
                            <th className="border-0">Pièces</th>
                            <th className="border-0">Passages</th>
                            <th className="border-0">Superficie</th>
                            <th className="border-0">Specificites</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                        {key === 'espaceVert' && (
                          <>
                            <th className="border-0">Type de prestation</th>
                            <th className="border-0">interventions</th>
                            <th className="border-0">Superficie</th>
                            <th className="border-0">Specificites</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {key === 'menage' && articlesMenage.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleMenage(index)}
                            />
                          </td>
                          <td>{article.typePiece}</td>
                          <td>{article.nombrePieces}</td>
                          <td>{article.nombrePassages}</td>
                          <td>{article.superficie}</td>
                          <td>{article.specificites.join(", ")}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                      {key === 'espaceVert' && articlesEspaceVert.map((articles, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleEspaceVert(index)}
                            />
                          </td>
                          <td>{articles.TypePrestation}</td>
                          <td>{articles.nombreInterventions}</td>
                          <td>{articles.superficieEV}</td>
                          <td>{articles.specificitesEV.join(", ")}</td>
                          <td>{articles.montant}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            {key === 'espaceVert' &&  (
              <>
              <Col md="4">
                <Card>
                  <Card.Header>
                    <Card.Title className='text-center' as="h4" style={welcomeStyle2}>Montant Total</Card.Title>
                  </Card.Header>  
                  <Card.Body>
                    <div style={{ fontSize: "0.9rem" }}>
                    <h5>Temps de travail : {(tempsEV / 60).toFixed(2).toString().split('.')[0]}H{(((parseInt((tempsEV / 60).toFixed(2).toString().split('.')[1])) / 100) * 60).toFixed(0)}M</h5>
                    <h5>Deplacement : {montantTotalEspaceVert.coutKilometres} €</h5>
                      <h5>Produits/Matériels : {isNaN(montantTotalEspaceVert.prixProduits) ? 0 : montantTotalEspaceVert.prixProduits} €</h5>
                      <h5>Coût de revient : {montantTotalEspaceVert.montantTotal} €</h5>
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0">Marge (%) :</h5>
                        <Form.Control
                          required
                          value={margeEspaceVert || 0}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= 0) {
                              setMargeEspaceVert(newValue);
                            }
                          }}
                          placeholder="Marge (%)"
                          type="number"
                          className="ml-2"
                          style={{ width: '100px' }} // Optionnel : pour contrôler la largeur du champ de saisie
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <h5 className="mb-0">Montant (HT) : {montantTotalEspaceVert.montantTotalHT} €</h5>
                        <Button className="btn-fill" type="button" variant="primary" onClick={handleValidationEspaceVert}>
                          <FaCheck />
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              </>
            )}
            {key === 'menage' &&  (
              <>
              <Col md="4">
                <Card>
                  <Card.Header>
                    <Card.Title className='text-center' as="h4" style={welcomeStyle2}>Montant Total</Card.Title>
                  </Card.Header>  
                  <Card.Body>
                    <div style={{ fontSize: "0.9rem" }}>
                      <h5>Temps de travail : {(tempsMenage / 60).toFixed(2).toString().split('.')[0]}H{(((parseInt((tempsMenage / 60).toFixed(2).toString().split('.')[1])) / 100) * 60).toFixed(0)}M</h5>
                      <h5>Deplacement : {montantTotalMenage.coutKilometres} €</h5>
                      <h5>Produits/Matériels : {isNaN(montantTotalMenage.prixProduits) ? 0 : montantTotalMenage.prixProduits} €</h5>
                      <h5>Coût de revient : {montantTotalMenage.montantTotal} €</h5>
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0">Marge (%) :</h5>
                        <Form.Control
                          required
                          value={margeMenage || 0}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= 0) {
                              setMargeMenage(newValue);
                            }
                          }}
                          placeholder="Marge (%)"
                          type="number"
                          className="ml-2"
                          style={{ width: '100px' }} // Optionnel : pour contrôler la largeur du champ de saisie
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <h5 className="mb-0">Montant (HT) : {montantTotalMenage.montantTotalHT} €</h5>
                        <Button className="btn-fill" type="button" variant="primary" onClick={handleValidationMenage}>
                          <FaCheck />
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              </>
            )}
          </Row>
        </>
      )}
    </Container>
  );
}  

export default Devis;
