import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Table,
  Nav,
  Modal
} from "react-bootstrap";
import { FaTrash, FaEdit, FaPlus, FaMinus, FaPen } from "react-icons/fa";

function Articles() {
  const [articles, setArticles] = useState([]);
  const [showMajoration, setShowMajoration] = useState(false);
  const [newArticle, setNewArticle] = useState({
    title: "",
    specifications: [],
    minPerSqm: "",
    service: "Menage", // Valeur par defaut
  });
  const [editIndex, setEditIndex] = useState(null);
  const [newSpecification, setNewSpecification] = useState("");
  const [majoration, setMajoration] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeTab, setActiveTab] = useState("view"); // Onglet actif par defaut

  // Fonction pour detecter si c'est la version mobile
  const isMobile = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 768; // Correspond à la largeur de l'ecran mobile selon Bootstrap
  };

  // Gerer les changements dans les champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewArticle({ ...newArticle, [name]: value });
  };

  // Sauvegarder un nouvel article ou mettre à jour un article existant
  const handleSave = () => {
    if (editIndex !== null) {
      const updatedArticles = [...articles];
      updatedArticles[editIndex] = newArticle;
      setArticles(updatedArticles);
    } else {
      setArticles([...articles, newArticle]);
    }
    resetForm();
  };

  // Reinitialiser le formulaire après sauvegarde
  const resetForm = () => {
    setNewArticle({
      title: "",
      specifications: [],
      minPerSqm: "",
      service: "Menage", // Reinitialisation à la valeur par defaut
    });
    setEditIndex(null);
    setShowEditModal(false);
  };

  // Gerer l'edition d'un article existant
  const handleEdit = (index) => {
    setNewArticle({ ...articles[index] });
    setEditIndex(index);
    setShowEditModal(true);
  };

  // Supprimer un article
  const handleDelete = (index) => {
    setArticles(articles.filter((_, i) => i !== index));
  };

  // Ajouter une nouvelle specification à l'article en cours de creation
  const handleAddSpecification = () => {
    setNewSpecification("");
    setShowMajoration(true);
  };

  // Sauvegarder une specification pour l'article en cours de creation
  const handleSaveSpecification = () => {
    setNewArticle({
      ...newArticle,
      specifications: [
        ...newArticle.specifications,
        { text: newSpecification, majoration },
      ],
    });
    setShowMajoration(false);
  };

  // Supprimer la dernière specification ajoutee à l'article en cours de creation
  const handleRemoveSpecification = () => {
    setNewArticle({
      ...newArticle,
      specifications: newArticle.specifications.slice(0, -1),
    });
  };

  // Style pour l'en-tête du titre
  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4" style={welcomeStyle}>Gestion des Articles</Card.Title>
            </Card.Header>
            <Card.Body>
              <Nav variant="tabs" activeKey={activeTab} onSelect={(key) => setActiveTab(key)} className="mb-3 flex-wrap">
                <Nav.Item>
                  <Nav.Link eventKey="view">{isMobile() ? "Consultation" : "Consulter les articles"}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="create">{isMobile() ? "Creation" : "Creer un article"}</Nav.Link>
                </Nav.Item>
              </Nav>
              {activeTab === 'create' && (
                <Form>
                  <Row className="mb-3">
                    <Col md="6">
                      <Form.Group>
                        <label>Titre</label>
                        <Form.Control
                          type="text"
                          placeholder="Entrer le titre"
                          name="title"
                          value={newArticle.title}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label>Prestations</label>
                        <Form.Control
                          as="select"
                          name="service"
                          value={newArticle.service}
                          onChange={handleInputChange}
                        >
                          <option value="Menage">Menage</option>
                          <option value="Espace Vert">Espace Vert</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">
                      <Form.Group>
                        <label>Nombre de min/m²</label>
                        <Form.Control
                          type="number"
                          placeholder="Entrer le nombre de min/m²"
                          name="minPerSqm"
                          value={newArticle.minPerSqm}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="12">
                      <Form.Group>
                        <label>Specificites</label>
                        {newArticle.specifications.map((spec, index) => (
                          <div key={index}>
                            {spec.text} (Majoration: {spec.majoration})
                          </div>
                        ))}
                        <div className="d-flex mt-2 gap-2">
                          <Button variant="success" onClick={handleAddSpecification}>
                            <FaPlus /> Ajouter Specificite
                          </Button>
                          <Button variant="danger" onClick={handleRemoveSpecification}>
                            <FaMinus /> Supprimer Dernière Specificite
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-end">
                      <Button variant="primary" onClick={handleSave}>Enregistrer</Button>
                    </Col>
                  </Row>
                </Form>
              )}
              {activeTab === 'view' && (
                <Table responsive className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Actions</th>
                      <th>Titre</th>
                      {!isMobile() && <th>Specificites</th>}
                      {!isMobile() && <th>Nombre de min/m²</th>}
                      <th>Prestations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articles.map((article, index) => (
                      <tr key={index}>
                        <td>
                          {isMobile() ? (
                            <>
                              <FaPen className="text-warning" style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(index)} />
                              <FaTrash className="text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)} />
                            </>
                          ) : (
                            <>
                              <Button variant="warning" onClick={() => handleEdit(index)}>
                                <FaPen /> Modifier
                              </Button>{" "}
                              <Button variant="danger" onClick={() => handleDelete(index)}>
                                <FaTrash /> Supprimer
                              </Button>
                            </>
                          )}
                        </td>
                        <td>{article.title}</td>
                        {!isMobile() && (
                          <>
                            <td>
                              <ul>
                                {article.specifications.map((spec, i) => (
                                  <li key={i}>
                                    {spec.text} (Majoration: {spec.majoration})
                                  </li>
                                ))}
                              </ul>
                            </td>
                            <td>{article.minPerSqm}</td>
                          </>
                        )}
                        <td>{article.service}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        backdrop="static"
        keyboard={false}
        size="lg" // Ajuster la taille à grande
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier l'article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col md="6">
                <Form.Group>
                  <label>Titre</label>
                  <Form.Control
                    type="text"
                    placeholder="Entrer le titre"
                    name="title"
                    value={newArticle.title}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>Prestations</label>
                  <Form.Control
                    as="select"
                    name="service"
                    value={newArticle.service}
                    onChange={handleInputChange}
                  >
                    <option value="Menage">Menage</option>
                    <option value="Espace Vert">Espace Vert</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="6">
                <Form.Group>
                  <label>Nombre de min/m²</label>
                  <Form.Control
                    type="number"
                    placeholder="Entrer le nombre de min/m²"
                    name="minPerSqm"
                    value={newArticle.minPerSqm}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="12">
                <Form.Group>
                  <label>Specificites</label>
                  {newArticle.specifications.map((spec, index) => (
                    <div key={index}>
                      {spec.text} (Majoration: {spec.majoration})
                    </div>
                  ))}
                  <div className="d-flex mt-2 gap-2">
                    <Button variant="success" onClick={handleAddSpecification}>
                      <FaPlus /> Ajouter Specificite
                    </Button>
                    <Button variant="danger" onClick={handleRemoveSpecification}>
                      <FaMinus /> Supprimer Dernière Specificite
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Articles;
