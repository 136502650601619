import React, { useState } from "react";
import { Row, Col, Form, Button, Card, Container, Tab, Nav, Alert } from "react-bootstrap";
import { AiOutlineUserAdd, AiOutlineUsergroupAdd, AiOutlineShop } from "react-icons/ai"; // Importation des icônes depuis react-icons

const cardTextStyle = {
  fontFamily: "Century Gothic, sans-serif",
};

function User() {
  const [selectedOption, setSelectedOption] = useState("");
  const [hoveredCard, setHoveredCard] = useState(null);
  const [formData, setFormData] = useState({
    siret: "",
    codeApe: "",
    tva: "",
    nom: "",
    telephone: "",
    email: "",
    adresse: "",
    ville: "",
    pays: "",
    codePostal: "",
    typeClient: "Particulier", // Ajout du type de client avec valeur par defaut "Particulier"
  });
  const [formErrors, setFormErrors] = useState({});
  const [activeTab, setActiveTab] = useState("adresse");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCardClick = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  // Fonction pour rendre l'icône en fonction du type de tiers
  function renderIcon(type) {
    switch (type) {
      case "Client":
        return <AiOutlineUserAdd size={30} color="#435aa0" />;
      case "Prospect":
        return <AiOutlineUsergroupAdd size={30} color="#435aa0" />;
      case "Fournisseur":
        return <AiOutlineShop size={30} color="#435aa0" />;
      default:
        return null;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation des champs dans les deux onglets
    const adresseErrors = validateAdresseForm();
    const adminErrors = validateAdminForm();

    if (Object.keys(adresseErrors).length === 0 && Object.keys(adminErrors).length === 0) {
      // Logique pour soumettre le formulaire
      console.log("Formulaire valide, soumission en cours...", formData);
      // Reinitialisation du formulaire après soumission
      resetForm();
    } else {
      const errors = { ...adresseErrors, ...adminErrors };
      setFormErrors(errors);

      // Determiner l'onglet à afficher en premier
      if (Object.keys(adresseErrors).length > 0) {
        setActiveTab("adresse");
      } else {
        setActiveTab("admin");
      }

      // Faire defiler jusqu'au premier champ d'erreur dans l'onglet actif
      const firstErrorField = Object.keys(errors)[0];
      const element = document.getElementsByName(firstErrorField)[0];
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const validateAdresseForm = () => {
    let errors = {};
    if (!formData.nom.trim()) {
      errors.nom = "Le nom est requis";
    }
    if (!formData.telephone.trim()) {
      errors.telephone = "Le numero de telephone est requis";
    }
    if (!formData.email.trim()) {
      errors.email = "L'adresse email est requise";
    }
    if (!formData.adresse.trim()) {
      errors.adresse = "L'adresse est requise";
    }
    if (!formData.ville.trim()) {
      errors.ville = "La ville est requise";
    }
    if (!formData.pays.trim()) {
      errors.pays = "Le pays est requis";
    }
    if (!formData.codePostal.trim()) {
      errors.codePostal = "Le code postal est requis";
    }
    return errors;
  };

  const validateAdminForm = () => {
    let errors = {};
    if (formData.typeClient === "Professionnel") {
      if (!formData.siret.trim()) {
        errors.siret = "Le SIRET est requis";
      } else if (!/^\d{14}$/.test(formData.siret.trim())) {
        errors.siret = "Le SIRET doit contenir exactement 14 chiffres";
      }
      if (!formData.codeApe.trim()) {
        errors.codeApe = "Le code APE est requis";
      } else if (!/^\d{5}$/.test(formData.codeApe.trim())) {
        errors.codeApe = "Le code APE doit contenir exactement 5 chiffres";
      }
      if (!formData.tva.trim()) {
        errors.tva = "Le numero de TVA est requis";
      }
    }
    return errors;
  };

  const resetForm = () => {
    setSelectedOption("");
    setFormData({
      siret: "",
      codeApe: "",
      tva: "",
      nom: "",
      telephone: "",
      email: "",
      adresse: "",
      ville: "",
      pays: "",
      codePostal: "",
      typeClient: "Particulier",
    });
    setFormErrors({});
    setActiveTab("adresse"); // Reinitialiser l'onglet actif après soumission
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handlePrev = () => {
    if (activeTab === "adresse") {
      setSelectedOption("");
    } else {
      setActiveTab("adresse");
    }
  };

  const handleNext = () => {
    setActiveTab(activeTab === "adresse" ? "admin" : "admin");
  };

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  const welcomeStyle2 = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  return (
    <Container fluid>
      {!selectedOption ? (
        <Row className="justify-content-center">
          <Col md="10" className="text-center">
            <h2 className="mt-1">
              <span style={welcomeStyle}>Creation de Tiers</span>
            </h2>
            <p className="lead mb-4">
              Choisissez le type de tiers que vous souhaitez creer.
            </p>
            <Row className="justify-content-center">
              {["Client", "Prospect", "Fournisseur"].map((option, index) => (
                <Col key={index} md="4" className="mb-4">
                  <Card
                    onClick={() => handleCardClick(option)}
                    style={{
                      backgroundColor: hoveredCard === index ? "#e0e0e0" : "#f0f0f0",
                      color: "#333",
                      border: "1px solid #ccc",
                      transition: "background-color 0.3s ease",
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className="h-100"
                  >
                    <Card.Body
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={cardTextStyle}
                    >
                      {renderIcon(option)}
                      <Card.Title className="mt-3 mb-3">{option}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center">
          <Col md="12">
            <Card className="p-4">
              <Card.Header>
                <Card.Title as="h2" style={welcomeStyle2}>Creation de {selectedOption}</Card.Title>
                {Object.keys(formErrors).length > 0 && (
                  <Alert variant="danger" className="mt-3 text-center">
                    Veuillez remplir tous les champs requis pour valider le tiers.
                  </Alert>
                )}
              </Card.Header>
              <Card.Body>
                <Tab.Container
                  id="tabs-form"
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                >
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="adresse">Adresse</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="admin">Donnees</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="adresse">
                      <Form>
                        <Row>
                          <Col className="pr-1" md="5">
                            <Form.Group>
                              <label>Nom / Raison sociale</label>
                              <Form.Control
                                name="nom"
                                value={formData.nom}
                                onChange={handleInputChange}
                                placeholder="Nom ou Raison sociale"
                                type="text"
                                isInvalid={!!formErrors.nom}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.nom}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" md="3">
                            <Form.Group>
                              <label>Telephone</label>
                              <Form.Control
                                name="telephone"
                                value={formData.telephone}
                                onChange={handleInputChange}
                                placeholder="01 23 45 67 89"
                                type="text"
                                isInvalid={!!formErrors.telephone}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.telephone}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label>Adresse mail</label>
                              <Form.Control
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Email"
                                type="email"
                                isInvalid={!!formErrors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="8">
                            <Form.Group>
                              <label>Adresse</label>
                              <Form.Control
                                name="adresse"
                                value={formData.adresse}
                                onChange={handleInputChange}
                                placeholder="Ex : 6 Allee de saint-cloud"
                                type="text"
                                isInvalid={!!formErrors.adresse}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.adresse}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label>Ville</label>
                              <Form.Control
                                name="ville"
                                value={formData.ville}
                                onChange={handleInputChange}
                                placeholder="Ex : Nancy"
                                type="text"
                                isInvalid={!!formErrors.ville}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.ville}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>Pays</label>
                              <Form.Control
                                name="pays"
                                value={formData.pays}
                                onChange={handleInputChange}
                                placeholder="Ex : France"
                                type="text"
                                isInvalid={!!formErrors.pays}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.pays}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" md="4">
                            <Form.Group>
                              <label>Code Postal</label>
                              <Form.Control
                                name="codePostal"
                                value={formData.codePostal}
                                onChange={handleInputChange}
                                placeholder="Ex : 54000"
                                type="number"
                                isInvalid={!!formErrors.codePostal}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.codePostal}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                      <Button className="float-left mt-3" onClick={handlePrev}>Precedent</Button>
                      <Button className="float-right mt-3" onClick={handleNext}>Suivant</Button>
                    </Tab.Pane>
                    <Tab.Pane eventKey="admin">
                      <Form>
                        <Row>
                          <Col className="pl-1" md="3">
                            <Form.Group>
                              <label>Type de Client</label>
                              <Form.Control
                                as="select"
                                name="typeClient"
                                value={formData.typeClient}
                                onChange={(e) => setFormData({ ...formData, typeClient: e.target.value })}
                              >
                                <option value="Particulier">Particulier</option>
                                <option value="Professionnel">Professionnel</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label>SIRET</label>
                              <Form.Control
                                name="siret"
                                value={formData.siret}
                                onChange={handleInputChange}
                                type="text"
                                isInvalid={!!formErrors.siret}
                                maxLength="14"
                                disabled={formData.typeClient === "Particulier"} // Desactiver le champ si Particulier est selectionne
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.siret}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="3">
                            <Form.Group>
                              <label>Numero de TVA</label>
                              <Form.Control
                                name="tva"
                                value={formData.tva}
                                onChange={handleInputChange}
                                type="text"
                                isInvalid={!!formErrors.tva}
                                disabled={formData.typeClient === "Particulier"} // Desactiver le champ si Particulier est selectionne
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.tva}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="2">
                            <Form.Group>
                              <label>Code APE</label>
                              <Form.Control
                                name="codeApe"
                                value={formData.codeApe}
                                onChange={handleInputChange}
                                type="text"
                                isInvalid={!!formErrors.codeApe}
                                maxLength="5"
                                disabled={formData.typeClient === "Particulier"} // Desactiver le champ si Particulier est selectionne
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.codeApe}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-1" md="2">
                            <Button className="float-left mt-3" onClick={handlePrev}>Precedent</Button>
                          </Col>
                          <Col className="float-right pl-1" md="10">
                            <Button className="float-right mt-3" onClick={handleSubmit}>Valider Tiers</Button>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default User;
