import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { AuthContext } from '../LoginPage/LoginPage';
import { useMsal } from "@azure/msal-react";
import { getUserDetails } from "../../assets/config/graph";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/Sidebar.css';

function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const history = useHistory();
  const { handleLogout } = useContext(AuthContext);
  const { instance, accounts } = useMsal();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [userProfile, setUserProfile] = useState(null);
  const [devisOpen, setDevisOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null); // État pour les sous-menus

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (accounts.length > 0) {
        try {
          const userDetailsResponse = await getUserDetails(instance, accounts);
          setUserProfile(userDetailsResponse);
        } catch (error) {
          console.error("Error fetching user details: ", error);
        }
      }
    };

    fetchUserProfile();
  }, [instance, accounts]);

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    handleLogout();
    history.push('/logout');
  };

  const toggleDevisDropdown = () => {
    setDevisOpen(!devisOpen);
    setActiveSubMenu(null); // Fermer les autres sous-menus lorsqu'on ouvre "Devis"
  };

  const toggleSubMenu = (menuName) => {
    setActiveSubMenu(activeSubMenu === menuName ? null : menuName);
  };

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: `url(${image})`
        }}
      />
      <div className="sidebar-wrapper d-flex flex-column justify-content-between">
        <div>
          <div className="logo d-flex flex-column align-items-center justify-content-center">
            <div className="user-img">
              <img 
                src={require("../../assets/img/user.png")} 
                alt="User Profile" 
              />
            </div>
            <a className="simple-text">
              {userProfile ? `${userProfile.givenName} ${userProfile.surname}` : ""}
            </a>
          </div>
          <Nav>
            {routes.filter(route => route.showInSidebar !== false).map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    {prop.name === "Devis" ? (
                      <>
                        <a 
                          href="#pablo" 
                          className={`nav-link ${devisOpen ? "active" : ""}`}
                          onClick={(e) => { e.preventDefault(); toggleDevisDropdown(); }}
                        >
                          <i className={prop.icon} />
                          <p className="d-flex align-items-center justify-content-between">
                            {prop.name}
                            <FontAwesomeIcon icon={devisOpen ? faChevronUp : faChevronDown} className="chevron-icon" />
                          </p>
                        </a>
                        <div className={`collapse ${devisOpen ? "show" : ""}`}>
                          <NavLink
                            to="/admin/devis/creation"
                            className={`nav-link nav-sub-link creation ${activeSubMenu === 'creation' ? 'active' : ''}`}
                            onClick={() => toggleSubMenu('creation')}
                          >
                            <i className="nc-icon nc-simple-add" />
                            <p>Création</p>
                          </NavLink>
                          <NavLink
                            to="/admin/devis/historique"
                            className={`nav-link nav-sub-link historique ${activeSubMenu === 'historique' ? 'active' : ''}`}
                            onClick={() => toggleSubMenu('historique')}
                          >
                            <i className="nc-icon nc-time-alarm" />
                            <p>Historiques</p>
                          </NavLink>
                        </div>
                      </>
                    ) : (
                      <NavLink
                        to={prop.layout + prop.path}
                        className={`nav-link ${activeRoute(prop.layout + prop.path)}`}
                        activeClassName="active"
                      >
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </NavLink>
                    )}
                  </li>
                );
              return null;
            })}
          </Nav>
        </div>
        {isMobile && (
          <div className="sidebar-footer">
            <div className="account-section" style={{ borderTop: "1px solid rgba(255, 255, 255, 0.2)" }}>
              <Nav>
                <li>
                  <NavLink to="/admin/admin-panel" className="nav-link" activeClassName="active">
                    <i className="nc-icon nc-settings-90" />
                    <p>Administrateur</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/user-profile" className="nav-link" activeClassName="active">
                    <i className="nc-icon nc-single-02" />
                    <p>Mon compte</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/logout" className="nav-link" onClick={handleLogoutClick}>
                    <i className="nc-icon nc-button-power" />
                    <p>Déconnexion</p>
                  </NavLink>
                </li>
              </Nav>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
