import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

const cardTextStyle = {
  fontFamily: "Century Gothic, sans-serif",
};

function CommandeMatierePremiere() {
  const [commands, setCommands] = useState([
    { id: 1, company: "Societe ABC", product: "Matière première 1", quantity: 10, address: "6 Allee de Saint-Cloud, Nancy, France, 54000" },
    { id: 2, company: "Societe XYZ", product: "Matière première 2", quantity: 5, address: "10 Rue des Lilas, Lyon, France, 69000" },
  ]);

  const [newCommand, setNewCommand] = useState({
    company: "",
    product: "",
    quantity: 0,
    address: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewCommand({ ...newCommand, [name]: value });
  };

  const addCommand = (event) => {
    event.preventDefault();
    const newId = commands.length > 0 ? commands[commands.length - 1].id + 1 : 1;
    const newCommandWithId = { ...newCommand, id: newId };
    setCommands([...commands, newCommandWithId]);
    setNewCommand({ company: "", product: "", quantity: 0, address: "" });
  };

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h4" style={welcomeStyle}> Gestion des Commandes de Matières Premières</Card.Title>
            </Card.Header>
            <Card.Body style={cardTextStyle}>
              <Form onSubmit={addCommand}>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Nom de la Societe</Form.Label>
                      <Form.Control
                        type="text"
                        name="company"
                        value={newCommand.company}
                        onChange={handleInputChange}
                        placeholder="Nom de la societe"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Produit</Form.Label>
                      <Form.Control
                        type="text"
                        name="product"
                        value={newCommand.product}
                        onChange={handleInputChange}
                        placeholder="Nom du produit"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Quantite</Form.Label>
                      <Form.Control
                        type="number"
                        name="quantity"
                        value={newCommand.quantity}
                        onChange={handleInputChange}
                        placeholder="Quantite"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Adresse de Livraison</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={newCommand.address}
                        onChange={handleInputChange}
                        placeholder="Adresse de livraison"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2" md={12}>
                    <Button className="float-right" type="submit" variant="info">
                      Ajouter Commande
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />
              <h5>Liste des Commandes</h5>
              <ul>
                {commands.map((command) => (
                  <li key={command.id}>
                    <strong>{command.company}</strong> - {command.product} - Quantite: {command.quantity} - Livraison à: {command.address}
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CommandeMatierePremiere;
