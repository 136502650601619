import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../config/auth-config"; // Assurez-vous d'avoir ce fichier correctement configuré
import axios from "axios";

/**
 * Attends un token pour accéder à l'API Microsoft Graph.
 * @param {Object} instance - L'instance MSAL.
 * @param {Array} accounts - Les comptes connectés.
 */
async function getToken(instance, accounts) {
  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  try {
    const response = await instance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await instance.acquireTokenPopup(request);
      return response.accessToken;
    } else {
      console.error("Erreur lors de l'obtention du jeton d'accès: ", error);
      throw error;
    }
  }
}

/**
 * Récupère les détails de l'utilisateur à partir de Microsoft Graph.
 * @param {Object} instance - L'instance MSAL.
 * @param {Array} accounts - Les comptes connectés.
 */
export async function getUserDetails(instance, accounts) {
  const token = await getToken(instance, accounts);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  try {
    const response = await axios.get("https://graph.microsoft.com/v1.0/me", config);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des détails de l'utilisateur: ", error);
    throw error;
  }
}
