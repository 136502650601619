import Accueil from "./views/Accueil.js";
import Articles from "./views/Articles.js";
import DevisCreation from "./views/DevisCreation.js";
import DevisHistorique from "./views/DevisHistorique.js";
import Tiers from "./views/Tiers.js";
import Controle from "./views/Controle.js";
import Utilisateurs from "./views/Utilisateurs.js";
import Commande from "./views/Commande.js";
import UserProfilePage from "./views/UserProfilePage.js";
import AdminPanel from "./views/AdminPanel.js";

const dashboardRoutes = [
  {
    path: "/accueil",
    name: "Accueil",
    icon: "nc-icon nc-bank",
    component: Accueil,
    layout: "/admin"
  },
  {
    path: "/articles",
    name: "Articles",
    icon: "nc-icon nc-bag",
    component: Articles,
    layout: "/admin"
  },
  {
    path: "/devis/creation",
    name: "Devis",
    icon: "nc-icon nc-notes",
    component: DevisCreation,
    layout: "/admin"
  },
  {
    path: "/tiers",
    name: "Tiers",
    icon: "nc-icon nc-circle-09",
    component: Tiers,
    layout: "/admin"
  },
  {
    path: "/controle",
    name: "Controle Qualitée",
    icon: "nc-icon nc-paper-2",
    component: Controle,
    layout: "/admin"
  },
  {
    path: "/commande",
    name: "Commande",
    icon: "nc-icon nc-cart-simple",
    component: Commande,
    layout: "/admin"
  },
  {
    path: "/utilisateurs",
    name: "Utilisateurs",
    icon: "nc-icon nc-badge",
    component: Utilisateurs,
    layout: "/admin"
  },
];

const routes = [
  ...dashboardRoutes,
  {
    path: "/user-profile",
    name: "User Profile",
    component: UserProfilePage,
    layout: "/admin",
    showInSidebar: false
  },
  {
    path: "/admin-panel",
    name: "Administrateur",
    component: AdminPanel,
    layout: "/admin",
    showInSidebar: false
  },
  {
    path: "/devis/historique",
    name: "Historique des devis",
    icon: "nc-icon nc-time-alarm",
    component: DevisHistorique,
    layout: "/admin",
    showInSidebar: false
  },
];

export default routes;
