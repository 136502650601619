import React, { useState } from "react";
import { Tabs, Tab, Card, Form, Button } from "react-bootstrap";
import dashboardRoutes from "../routes.js";

const AdminPanel = () => {
  const [tarifDevis, setTarifDevis] = useState(0);
  const [articleDetails, setArticleDetails] = useState("");
  const [controleQuality, setControleQuality] = useState("");
  const [userDetails, setUserDetails] = useState("");

  const handleTarifDevisChange = (e) => setTarifDevis(e.target.value);
  const handleArticleDetailsChange = (e) => setArticleDetails(e.target.value);
  const handleControleQualityChange = (e) => setControleQuality(e.target.value);
  const handleUserDetailsChange = (e) => setUserDetails(e.target.value);

  const handleTarifDevisSubmit = (e) => {
    e.preventDefault();
    // Logique pour soumettre le tarif des devis mis à jour
    console.log("Tarif Devis:", tarifDevis);
  };

  const handleArticleDetailsSubmit = (e) => {
    e.preventDefault();
    // Logique pour soumettre les details des articles mis à jour
    console.log("Article Details:", articleDetails);
  };

  const handleControleQualitySubmit = (e) => {
    e.preventDefault();
    // Logique pour soumettre le contrôle qualite mis à jour
    console.log("Controle Quality:", controleQuality);
  };

  const handleUserDetailsSubmit = (e) => {
    e.preventDefault();
    // Logique pour soumettre les details des utilisateurs mis à jour
    console.log("User Details:", userDetails);
  };

  // Filtrer les routes pour exclure Accueil, UserProfile et Administrateur
  const filteredRoutes = dashboardRoutes.filter(
    (route) =>
      route.path !== "/accueil" &&
      route.path !== "/user-profile" &&
      route.path !== "/admin-panel"
  );

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h3">Panneau d'Administration</Card.Title>
      </Card.Header>
      <Card.Body>
        <Tabs defaultActiveKey="articles" id="admin-panel-tabs">
          {filteredRoutes.map((route, index) => (
            <Tab eventKey={route.name.toLowerCase()} title={route.name} key={index}>
              {route.path === "/devis" && (
                <Form onSubmit={handleTarifDevisSubmit}>
                  <Form.Group controlId="formTarifDevis">
                    <Form.Label>Tarif Devis</Form.Label>
                    <Form.Control
                      type="number"
                      value={tarifDevis}
                      onChange={handleTarifDevisChange}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Mettre à jour
                  </Button>
                </Form>
              )}
              {route.path === "/articles" && (
                <Form onSubmit={handleArticleDetailsSubmit}>
                  <Form.Group controlId="formArticleDetails">
                    <Form.Label>Details des Articles</Form.Label>
                    <Form.Control
                      type="text"
                      value={articleDetails}
                      onChange={handleArticleDetailsChange}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Mettre à jour
                  </Button>
                </Form>
              )}
              {route.path === "/controle" && (
                <Form onSubmit={handleControleQualitySubmit}>
                  <Form.Group controlId="formControleQuality">
                    <Form.Label>Contrôle Qualite</Form.Label>
                    <Form.Control
                      type="text"
                      value={controleQuality}
                      onChange={handleControleQualityChange}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Mettre à jour
                  </Button>
                </Form>
              )}
              {route.path === "/utilisateurs" && (
                <Form onSubmit={handleUserDetailsSubmit}>
                  <Form.Group controlId="formUserDetails">
                    <Form.Label>Details des Utilisateurs</Form.Label>
                    <Form.Control
                      type="text"
                      value={userDetails}
                      onChange={handleUserDetailsChange}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Mettre à jour
                  </Button>
                </Form>
              )}
              {/* Ajoutez des formulaires similaires pour d'autres sections si necessaire */}
            </Tab>
          ))}
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default AdminPanel;
