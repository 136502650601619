import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";

import AdminNavbar from "../components/Navbars/AdminNavbar";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";

import routes from "../routes.js";
import sidebarImage from "../assets/img/207a3eb0361f46e5cf5c4b0563a2e995.jpg";
import loginPageImage from "../assets/img/wave.jpg"; // Image de la page de login

function Admin() {
  const [image, setImage] = useState(null); // Par défaut, pas d'image de fond
  const [color, setColor] = useState("black");
  const [hasImage, setHasImage] = useState(false); // Pas d'image par défaut
  const location = useLocation();
  const mainPanel = useRef(null);

  useEffect(() => {
    // Vérifier l'URL pour définir l'image de fond appropriée
    const path = location.pathname;

    if (path === "/admin/login") {
      setImage(loginPageImage); // Image spécifique pour la page de login
      setHasImage(true);
    } else {
      setImage(null); // Pas d'image pour les autres pages
      setHasImage(false);
    }

    // Code pour gérer le scroll et la navigation
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      if (element) {
        element.parentNode.removeChild(element);
      }
    }
  }, [location]);

  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Router>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>
              {getRoutes()}
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default Admin;
